import { ref } from 'vue';
export default {
  name: "EleDialog",
  props: {
    imgpath: {
      type: String,
      required: true
    }
  },
  data() {
    // return {
    //     cardImgList: require('../assets/dialog_qr_code.png'),
    // };
  },
  setup() {
    const dialogVisble = ref(false);
    const confirm = () => {
      console.log('你点击了确定按钮');
      dialogVisble.value = false;
    };
    const close = () => {
      dialogVisble.value = false;
    };
    const handleClose = function () {
      close();
    };
    return {
      dialogVisble,
      confirm,
      close,
      handleClose
    };
  }
};