import { ref } from 'vue';
import MySwiperCarousel from "@/components/MySwiperCarousel.vue";
import MyEleDialog from "@/components/MyEleDialog.vue";
export default {
  name: "GameHome",
  components: {
    MySwiperCarousel,
    MyEleDialog
  },
  props: {
    msg: String
  },
  created() {
    console.log("页面加载:", this.GetQueryString('id'));
    if (this.GetQueryString('id') == null) {
      this.customerImgPath = require('../assets/dialog_qr_code.png');
      this.customerPhone = '17673118851';
    }
    if (this.GetQueryString('id') == '001') {
      this.customerImgPath = require('../assets/dialog_qr_code_001.png');
      this.customerPhone = '18900750982';
    }
    if (this.GetQueryString('id') == '002') {
      this.customerImgPath = require('../assets/dialog_qr_code_002.png');
      this.customerPhone = '18692480918';
    }
    console.log("联系客服二维码路径:", this.customerImgPath);
  },
  data() {
    return {
      Loop: '',
      //  定时器 
      qrcodeUrl: '',
      // 后端返回的二维码图片路径
      qrcodeUrl64: '',
      //是后端返回二维码图片的二进制流
      customerImgPath: require('../assets/dialog_qr_code.png'),
      customerPhone: '17673118851'
    };
  },
  setup() {
    const visibleDialog = ref(null);
    const open = () => {
      console.log("点击了");
      console.log(visibleDialog.value.dialogVisble);
      visibleDialog.value.dialogVisble = true;
      console.log(visibleDialog.value.dialogVisble);
    };
    return {
      visibleDialog,
      open
    };
  },
  methods: {
    touchEnd() {
      //手指离开
      clearTimeout(this.Loop);
    },
    touchStart() {
      //手指触摸
      clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
      this.Loop = setTimeout(() => {
        this.downloadIamge(this.qrcodeUrl64, "pic");
      }, 1000);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.src = imgsrc;
    },
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    }
  }
};