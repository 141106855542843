// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Autoplay 自动播放
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// Import Swiper styles
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {};
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    const navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      disabledClass: 'disable'
    };
    const pagination = {
      clickable: true,
      bulletClass: 'dot',
      bulletActiveClass: 'actived',
      el: '.pagination'
    };
    return {
      navigation,
      pagination,
      modules: [Navigation, Pagination, Autoplay],
      onSwiper,
      onSlideChange
    };
  }
};